import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/default.js";
import { Section } from 'src/components/molecules/Section';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section style={{
      maxWidth: '880px',
      marginLeft: 'auto',
      marginRight: 'auto'
    }} mdxType="Section">
      <Section style={{
        maxWidth: '440px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '-160px'
      }} mdxType="Section">
        <p><img parentName="p" {...{
            "src": "https://www.datocms-assets.com/30881/1645000843-thankyou_illustration.png",
            "alt": null
          }}></img></p>
      </Section>
      <h2 {...{
        "id": "your-enterprise-is-ready-to-build-scalable-next-level-customer-experiences-what-comes-next"
      }}>{`Your enterprise is ready to build scalable next-level customer experiences. What comes next?`}</h2>
      <p>{`Great news! You’ll receive an email from one of our Rasa team members soon. In the meantime, use these curated resources to get a head start: `}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://rasa.com/customers/"
          }}>{`Check out how our customers are using Rasa today`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://info.rasa.com/enterprise-success-with-conversational-ai-webinar-series"
          }}>{`Watch these customer stories on demand`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://rasa.com/rasa-events/"
          }}>{`Sign up for upcoming Rasa events`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.linkedin.com/company/rasa"
          }}>{`Follow us on LinkedIn`}</a></li>
      </ul>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      